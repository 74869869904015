exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banery-js": () => import("./../../../src/pages/banery.js" /* webpackChunkName: "component---src-pages-banery-js" */),
  "component---src-pages-generate-link-js": () => import("./../../../src/pages/GenerateLink.js" /* webpackChunkName: "component---src-pages-generate-link-js" */),
  "component---src-pages-generator-maili-js": () => import("./../../../src/pages/generator-maili.js" /* webpackChunkName: "component---src-pages-generator-maili-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pomoce-js": () => import("./../../../src/pages/pomoce.js" /* webpackChunkName: "component---src-pages-pomoce-js" */),
  "component---src-pages-product-basics-js": () => import("./../../../src/pages/product-basics.js" /* webpackChunkName: "component---src-pages-product-basics-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-redirect-page-backup-js": () => import("./../../../src/pages/RedirectPageBackup.js" /* webpackChunkName: "component---src-pages-redirect-page-backup-js" */),
  "component---src-pages-redirect-page-js": () => import("./../../../src/pages/RedirectPage.js" /* webpackChunkName: "component---src-pages-redirect-page-js" */),
  "component---src-pages-stopki-js": () => import("./../../../src/pages/stopki.js" /* webpackChunkName: "component---src-pages-stopki-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

